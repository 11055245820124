import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>What’s up with the name Purple Cow Internet? 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
        <h1>What’s up with the name Purple Cow Internet?</h1>
        <p className="font-bold">
        Golly! I get that question a lot. Why did we name the business <a href="www.purplecowinternet.com">Purple Cow</a>?
        </p>
        <p>
        Well I’m here to answer just that question. It started about 5 years ago after moving home to Nova Scotia from the United States. After researching available providers I was blown away by the high price of internet in Nova Scotia and did not understand why in other places around the world it is a lot less expensive.        
        </p>
        <p>
        It seemed to me us little Nova Scotian's were probably getting taken advantage of and seemed like the perfect problem to try to solve.        
        </p>
        <p>
        So I decided I was going to start my own with the goal of being the best internet service provider in Nova Scotia. One of the coolest privileges you have when setting up a business is picking a name.        
        </p>
        <p>
        There are lots of things to think about when picking a name, for example does it tell the customer what you do? Is the URL available or is the name memorable? I know this is going to seem cliche, however somehow I stumbled upon a name that checked all the boxes while I was away on a guys fishing trip just before falling asleep.          
        </p>
        <p>
        When going to bed I often listen to white noise on my phone. This night I was listening to one of my favorite marketing books called “Purple Cow”.        
        </p>
        <p>
        This book talks about how a company nowadays needs to have a remarkable idea, product, or service that will differentiate them from competitors to be successful. These companies that stand out are called purple cows and people talk about them.        
        </p>
        <p>
        Knowing I wanted to build something kick ass and special in the internet industry, I drew a lot of inspiration from this book. Just as I was falling asleep the idea hit me, I pulled up the notes section of my phone and wrote down “the business will be named Purple Cow Internet.”        
        </p>
        <p>
        So, there you have it!        
        </p>
        <p>
        Purple Cow Internet has become the best internet provider in Halifax, Nova Scotia, and has expanded to other provinces like Prince Edward Island and Newfoundland. I think a lot of our success has to do with us being a Purple Cow. Moooooooo!        
        </p>

            <p>
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>
          </p>

      </section>
    </LayoutNew>
  )
}
